import React from 'react';
import BottomBarButton from './BottomBarButton'; // Import the BottomBarButton component
import './BottomBar.css'; // Import the BottomBar styles
import { useDispatch, useSelector } from "react-redux";


import {
    toggleCoordinateSystem,
    requestStickerOrganization,
    setWeaponModelForActiveTab,
    requestRemoveAllMeshes,
    removeAllStickersInTab,
    requestCanvasClearing,
    requestWeaponModelChange,
    toggleHelpSection,
} from "../../../../redux/slices/tabSystemSlice";

const BottomBar = () => {

    const dispatch = useDispatch();
    const tabs = useSelector((state) => state.tab_system.tabs);
    const activeTab = useSelector((state) => state.tab_system.active_tab);


    const handleCoordinateClick = () => {
        dispatch(toggleCoordinateSystem())
    };

    const handleGunClick = () => {
        dispatch(
            setWeaponModelForActiveTab({
                tabId: activeTab,
                weaponModel: {},
            })
        );
        dispatch(requestWeaponModelChange());

    };

    const handleOrganizeClick = () => {
        dispatch(requestStickerOrganization())

    };

    const handleTrashClick = () => {
        dispatch(removeAllStickersInTab({ tabId: activeTab }))
        dispatch(requestCanvasClearing());

    };

    const handleInGameClick = () => {
        console.log('In Game button clicked!');
    };

    const handleHelpClick = () => {
        dispatch(toggleHelpSection()); // Toggles the help section visibility

    };



    return (
        <div className="bottom-bar">

            <BottomBarButton
                size="small"
                icon="images/BottomBar/info.png" // Using image as icon
                isToggle={false} // Make this button toggleable
                onClick={handleHelpClick}  // Unique onClick for this button
            />
            {/* First four buttons with small size */}
            <BottomBarButton
                size="small"
                icon="images/BottomBar/coordinate.png" // Using image as icon
                isToggle={false} // Make this button toggleable
                onClick={handleCoordinateClick}  // Unique onClick for this button
            />
            <BottomBarButton
                size="small"
                icon="images/BottomBar/organize.png" // Using image as icon
                isToggle={false} // Make this button toggleable
                onClick={handleOrganizeClick}  // Unique onClick for this button
            />
            <BottomBarButton
                size="small"
                icon="images/BottomBar/gun.png" // Using image as icon
                isToggle={false} // Make this button toggleable
                onClick={handleGunClick}  // Unique onClick for this button
            />
            <BottomBarButton
                size="small"
                icon="images/BottomBar/trash.png" // Using image as icon
                isToggle={false} // Make this button toggleable
                onClick={handleTrashClick}  // Unique onClick for this button
            />

            {/* Fifth button with large size */}
            <BottomBarButton
                size="large"
                text="In Game" // Add text for large button
                icon="images/BottomBar/ingame.png" // Using image as icon
                isToggle={false} // Make this button not toggleable
                onClick={handleInGameClick}  // Unique onClick for this button
            />

        </div>
    );
};

export default BottomBar;
