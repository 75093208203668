import React, { useEffect, useRef, useState } from "react";
import "../../../App.css";
import "./ThisCraftBar.css";
import SelectWeaponField from "./SelectWeaponField";
import { useCart } from "../../TopBar/cart/CartContext";
import { useDispatch, useSelector } from "react-redux";
import {
    requestStickerSelection,
    setSelectedStickerIndex,
    removeSticker,
    requestStickerReload,
    removeAllStickersInTab,
    verifyCanvasClearing,
    requestCanvasClearing,
    changeStickerRenderOrder,
    requestRemoveSelectedSticker,
} from "../../../redux/slices/tabSystemSlice";

const calculateDisplayCurrencyPrice = (price, user) => {

    // Check if user is logged in
    if (Object.keys(user).length > 0) {
        var steam_price = (price * user.currency_multiplier).toFixed(2);

        if (price !== 0) {
            return (steam_price * user.currency_multiplier).toFixed(2).toString();
        } else {
            return "?";
        }
    } else {
        return "EUR " + price.toString();
    }

};

const UnselectedStickerBox = ({
    name,
    price,
    steam_price,
    img,
    index,
    listing_id,
}) => {
    const dispatch = useDispatch();
    const activeTab = useSelector((state) => state.tab_system.active_tab);
    const redux_cart = useSelector((state) => state.cart_session);
    const user = useSelector((state) => state.user);

    const handleStickerClick = () => {
        dispatch(
            setSelectedStickerIndex({
                tabId: activeTab,
                selectedIndex: index,
            })
        );
        dispatch(requestStickerSelection());
    };

    return (
        <div
            className="unselected-sticker-box"
            onClick={handleStickerClick}
            style={{ cursor: "pointer" }}
        >
            <div className="unselected-sticker-box-left">
                <img
                    src={img}
                    style={{
                        width: "80%",
                        height: "80%",
                        marginLeft: "10px",
                        marginTop: "10px",
                        objectFit: "cover",
                        objectPosition: "center",

                        /* Prevent dragging images */
                        "-webkit-user-drag": "none", /* Prevent image drag in WebKit browsers */
                        "user-drag": "none",         /* Prevent image drag in other browsers */

                        /* Prevent interaction (no clicking, no dragging) */
                        "pointer-events": "none",    /* Disable interaction with the image itself */
                    }}
                    alt="Unselected sticker"
                />


            </div>
            <div className="unselected-sticker-box-right">
                <p className="unselected-sticker-name">{name}</p>

                {listing_id != null && redux_cart.cart_enabled ? (
                    <p className="unselected-sticker-price">
                        {/* {user.display_currency} {calculateDisplayCurrencyPrice(price, user)} */}
                    </p>
                ) : (
                    <p className="unselected-sticker-price">
                        Steam Price: {user.display_currency}{" "}
                        {calculateDisplayCurrencyPrice(steam_price, user)}
                    </p>
                )}
            </div>
        </div>
    );
};

export default UnselectedStickerBox;