import React, { act, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import WatermarkGrid from "./old/Watermark/WaterMarkGrid";
import {
  createDecalMaterial,
  createStickerMaterial,
  createWeaponMaterial,
} from "./ThreeMaterials"; // Import shader material functions
import SelectedRightClickMenu from "./ContextMenus/SelectedRightClickMenu/SelectedRightClickMenu";
import { useCart } from "../../TopBar/cart/CartContext";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { loadCoordinateSystem } from "./CoordinateSystemUtils"; // Import the function
import { loadTrashCan } from "./TrashcanUtils"; // Import the function

import LoadWeaponModel from "./LoadWeaponModel"; // Import the loadWeaponModel function

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  rotateSticker,
  removeSticker,
  setSelectedStickerIndex,
  updateStickerPosition,
  setDecalIntersectionPoint,
  changeStickerRenderOrder,
  verifyStickerReload,
  requestStickerReload,
  verifyWeaponModelChange,
  requestWeaponModelChange,
  verifyStickerAdd,
  verifyStickerSelection,
  setCopiedSticker,
  requestStickerPaste,
  verifyStickerPaste,
  addSticker,
  removeAllStickersInTab,
  verifyCanvasClearing,
  requestCanvasClearing,
  setStickerPasteCoordinates,
  requestStickerOrganization,
  verifyStickerOrganization,
  setWeaponModelForActiveTab,
  lockSticker,
  setRotationZero,
  verifyRemoveSelectedSticker,
  requestRemoveAllMeshes,
  verifyRemoveAllMeshes,
  requestRemoveSelectedSticker
} from "../../../redux/slices/tabSystemSlice";
import { DecalGeometry } from "three/addons/geometries/DecalGeometry.js";
import UnSelectedMenuCell from "./ContextMenus/UnSelectedRightClickMenu/UnSelectedMenuCell";
import UnSelectedRightClickMenu from "./ContextMenus/UnSelectedRightClickMenu/UnSelectedRightClickMenu";

import {
  addItem,
  makeStockPopupVisible,
} from "../../../redux/slices/cartSessionSlice";
import { setPopupData } from "../../../redux/slices/popupDateSlice";

import SignInPopupModal from "../../PopupModals/SignInModal/SignInPopupModal";
import BottomBar from "./BottomBar/BottomBar";
import HelpSection from "./HelpSection/HelpSection";

const GraphicsScene = () => {
  // redux state
  const tabs = useSelector((state) => state.tab_system.tabs);
  const activeTab = useSelector((state) => state.tab_system.active_tab);
  const clip_board = useSelector((state) => state.tab_system.clipBoard);

  const dispatch = useDispatch();

  // references
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(
    new THREE.PerspectiveCamera(
      75, // Field of View
      window.innerWidth / window.innerHeight, // Aspect Ratio
      0.1, // Near Clipping Plane
      10000 // Far Clipping Plane
    )
  );

  const controlsRef = useRef(null);

  const [weaponModel, setWeaponModel] = useState(null);
  const [stickers, setStickers] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);

  useEffect(() => {
    cameraRef.current.position.set(0, 0, 150);

    cameraRef.current.lookAt(0, 0, 0);

    const canvas = canvasRef.current;

    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
    });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.sortObjects = true;
    renderer.physicallyCorrectLights = true;
    rendererRef.current = renderer;
    renderer.localClippingEnabled = true;

    const controls = new OrbitControls(cameraRef.current, renderer.domElement);
    controls.minDistance = 70;
    controls.maxDistance = 300;
    controls.zoomToCursor = true;
    controls.zoomSpeed = 1.3;
    controlsRef.current = controls;

    //loadCoordinateSystem(sceneRef.current);
    loadTrashCan(sceneRef.current);

    handleResize();

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(sceneRef.current, cameraRef.current);
    };

    animate();

    window.addEventListener("resize", handleResize);

    return () => {
      renderer.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (tabs[activeTab]) {
      setWeaponModel(tabs[activeTab].weaponModel);
    }
  }, [tabs, activeTab]);

  useEffect(() => {
    if (weaponModel) {
      LoadWeaponModel(weaponModel, sceneRef);
    }
  }, [weaponModel]);

  const handleResize = () => {
    const container = containerRef.current;
    const renderer = rendererRef.current;
    const camera = cameraRef.current;

    const width = container.clientWidth;
    const height = container.clientHeight;
    renderer.setSize(width, height);
    cameraRef.current.aspect = width / height;
    cameraRef.current.updateProjectionMatrix();
  };

  // ---------STICKERS------------

  const isPlaneMoving = useRef(false);
  const localStickersApplied = useRef([]);
  const localSelectedStickerIx = useRef(-1);
  const localWeaponModel = useRef(-1);

  // basicaly copy of localstickersapplied
  const localAllStickers = useRef([]);


  useEffect(() => {
    setStickers(tabs[activeTab].stickers_applied);
  }, [tabs[activeTab].index]);

  useEffect(() => {
    removeAllStickerPlanes();
    removeAllStickerDecals();

    localStickersApplied.current = tabs[activeTab].stickers_applied;
    localStickersApplied.current = localStickersApplied.current.filter(
      (sticker) => !sticker.has_been_removed
    );

    loadTabsStickers();

    const handleMouseMove = (event) => onMouseMove(event);
    const handleMouseUp = (event) => onMouseUp(event);
    const handleMouseDown = (event) => onMouseDown(event);
    const handleContextMenu = (event) => onMouseDown(event);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup function
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [tabs[activeTab].index]);

  /**
   * Listen for stickers being added.
   */
  useEffect(() => {
    if (tabs[activeTab].sticker_has_been_added) {
      const sticker_to_load = tabs[activeTab].stickers_applied.slice(-1)[0];
      loadSticker(sticker_to_load);
      dispatch(verifyStickerAdd());
      dispatch(requestStickerReload())
    }
  }, [tabs[activeTab].sticker_has_been_added]);

  /**
   * Listen for stickers being pasted.
   */
  useEffect(() => {
    if (clip_board.sticker_has_been_pasted) {
      const sticker_to_load = clip_board.copied_sticker;
      loadSticker(sticker_to_load);

      const x_paste_cord = clip_board.x_paste_cord;
      const y_paste_cord = clip_board.y_paste_cord;

      dispatch(
        addSticker({
          tabId: activeTab,
          sticker: {
            name: sticker_to_load.name,
            price: sticker_to_load.price,
            quantity: 1,
            image_path: sticker_to_load.image_path,
            x_pos: sticker_to_load.x_pos + 5,
            y_pos: sticker_to_load.y_pos + 5,
            //x_pos: x_paste_cord,
            //y_pos: y_paste_cord,
            z_pos: 0,
            z_render_order: 0,
            clockwise_rotatation: 0,
            decalIntersectionPoint: null,
            has_been_removed: false,
          },
        })
      );
      dispatch(verifyStickerPaste());
    }
  }, [clip_board.sticker_has_been_pasted]);

  /**
   * Listen for canvas being cleared.
   */


  useEffect(() => {
    removeAllStickerPlanes();
    removeAllStickerDecals();
    dispatch(verifyCanvasClearing());
  }, [tabs[activeTab].should_clear_canvas]);

  /**
   * Listen for stickers being selected.
   */
  useEffect(() => {
    if (tabs[activeTab].sticker_has_been_selected) {
      const scene = sceneRef.current;
      const selected_sticker_index = tabs[activeTab].selected_sticker_index;

      // Update plane
      const planeName = `planeMesh-${selected_sticker_index}`; // Use sticker.index for unique naming
      const plane = scene.getObjectByName(planeName);
      if (plane) {
        addStickerBorder(plane);
      }

      dispatch(verifyStickerSelection());
    }
  }, [tabs[activeTab].sticker_has_been_selected]);


  /**
   * 
   * Verify remove all meshes 
   * Not actually used at the momment
   */

  useEffect(() => {
    if (tabs[activeTab].remove_all_meshes) {
      const scene = sceneRef.current;

      scene.clear();
      console.log("REMOVE ALL OBJECTS FROM SCENE");

      dispatch(verifyRemoveAllMeshes()); // set redux variable
    } else {

    }
  }, [tabs[activeTab].remove_all_meshes]);


  /**
   * coordinate
   */

  useEffect(() => {

    const scene = sceneRef.current;
    if (tabs[activeTab].coordinate_system) {

      loadCoordinateSystem(scene);

    } else {
      const existingXAxis = scene.getObjectByName('xAxis');
      const existingYAxis = scene.getObjectByName('yAxis');
      const existingZAxis = scene.getObjectByName('zAxis');

      if (existingXAxis) {
        scene.remove(existingXAxis);
        existingXAxis.geometry.dispose();
        existingXAxis.material.dispose();
      }
      if (existingYAxis) {
        scene.remove(existingYAxis);
        existingYAxis.geometry.dispose();
        existingYAxis.material.dispose();
      }
      if (existingZAxis) {
        scene.remove(existingZAxis);
        existingZAxis.geometry.dispose();
        existingZAxis.material.dispose();
      }

    }
  }, [tabs[activeTab].coordinate_system]);

  /**
   * Listen for a sticker being deleted from sidebar.
   */
  useEffect(() => {
    // remove sticker
    removeStickerDecal(localSelectedStickerIx.current);
    // remove decal
    removeStickerPlane(localSelectedStickerIx.current);

    dispatch(verifyRemoveSelectedSticker());
  }, [tabs[activeTab].remove_selected_sticker]);

  /**
   * Set selected sticker index (local) when it changes in redux.
   */
  useEffect(() => {
    localSelectedStickerIx.current = tabs[activeTab].selected_sticker_index;
  }, [tabs[activeTab].selected_sticker_index]);


  useEffect(() => {
    localWeaponModel.current = tabs[activeTab].weaponModel;
  }, [tabs[activeTab].weaponModel]);

  useEffect(() => {
    localAllStickers.current = tabs[activeTab].stickers_applied;
    localAllStickers.current = localAllStickers.current.filter(
      (sticker) => !sticker.has_been_removed
    );
  }, [tabs[activeTab].stickers_applied])

  /**
   * UPDATE STICKER Z INDEX / RENDER ORDER
   * DEPENDING ON REDUX RENDER ORDER.
   *
   * GETS CALLED WHEN REQUESTING STICKER RELOAD
   * IN REDUX TAB.
   */
  useEffect(() => {
    if (tabs[activeTab].should_reload_stickers) {
      const updatedStickers = tabs[activeTab].stickers_applied;
      const delta = 0.2;
      const scene = sceneRef.current;

      updatedStickers.forEach((sticker) => {
        if (!sticker.has_been_removed) {
          const render_order = sticker.z_render_order;
          const rotation_angle = sticker.clockwise_rotatation;
          const decalIntersectPoint = sticker.decalIntersectionPoint;

          // Update plane
          const planeName = `planeMesh-${sticker.index}`; // Use sticker.index for unique naming
          const plane = scene.getObjectByName(planeName);
          if (plane) {
            plane.rotation.z = rotation_angle;
            plane.position.x = sticker.x_pos;
            plane.position.y = sticker.y_pos;
            plane.position.z = render_order * delta;
            plane.material.needsUpdate = true;
            plane.renderOrder = render_order;
          }

          // Update decal if available
          const decalName = `decal-${sticker.index}`;
          const decal = scene.getObjectByName(decalName);
          if (decal && decalIntersectPoint) {
            decal.renderOrder = render_order + 4;
            decal.material.polygonOffsetFactor = 0;
            decal.material.polygonOffsetUnits = -4 * (render_order + 1);
            decal.material.needsUpdate = true;
          }
        }
      });

      dispatch(verifyStickerReload());
    }
  }, [tabs[activeTab].should_reload_stickers]);

  useEffect(() => {
    if (tabs[activeTab].should_organize_stickers) {
      // Filter out stickers that have been removed
      const visibleStickers = tabs[activeTab].stickers_applied.filter(
        (sticker) => !sticker.has_been_removed
      );

      // Retrieve the count of applied (non-removed) stickers
      const sticker_cnt = visibleStickers.length;

      // Define starting position and increments
      const centerY = 55; // This will be constant
      const increment = 30; // Space between stickers

      // Calculate total width required for stickers in a single row
      const totalWidth = (sticker_cnt - 1) * increment;

      // Calculate the center offset to align stickers horizontally
      const centerXOffset = -totalWidth / 2;

      // Create a copy of updated stickers to update positions
      const updatedStickers = visibleStickers.map((sticker, index) => {
        const xPos = centerXOffset + index * increment;
        const yPos = centerY; // Fixed vertical position

        // Dispatch the update action to change position
        dispatch(
          updateStickerPosition({
            tabId: activeTab,
            stickerIndex: sticker.index, // Ensure `sticker.index` matches the actual identifier for your stickers
            xPos: xPos,
            yPos: yPos,
            zPos: 0, // Stay at 0
          })
        );

        // Return a new sticker object with updated positions
        return {
          ...sticker,
          x_pos: xPos,
          y_pos: yPos,
        };
      });

      // Dispatch actions for each sticker update
      updatedStickers.forEach((sticker) => {
        if (!sticker.has_been_removed) {
          // Handle 3D Plane
          const planeName = `planeMesh-${sticker.index}`; // Use sticker.index for unique naming
          const plane = sceneRef.current?.getObjectByName(planeName);

          if (plane) {
            // Update plane position
            plane.position.set(sticker.x_pos, sticker.y_pos, sticker.z_render_order * 0.2);
            plane.rotation.z = 0;

            plane.material.needsUpdate = true;
          }

          // Reset sticker rotation and decal intersection point
          dispatch(
            setRotationZero({
              tabId: activeTab,
              stickerIndex: sticker.index,
            })
          );

          dispatch(
            setDecalIntersectionPoint({
              tabId: activeTab,
              stickerIndex: sticker.index,
              intersectionPoint: null,
            })
          );
        }
      });

      // Cleanup and additional actions
      removeAllStickerDecals();
      showMaterialInAllStickerPlanes();

      dispatch(verifyStickerOrganization());
    }
  }, [tabs[activeTab].should_organize_stickers, tabs[activeTab].stickers_applied, activeTab]);


  useEffect(() => {
    /**
     * 1) Remove all decals
     * 2) Make all plane materials visible
     */

    removeAllStickerDecals();
    showMaterialInAllStickerPlanes();

    dispatch(verifyWeaponModelChange());
  }, [tabs[activeTab].weapon_model_has_changed]);

  /**
   * Show material in all planes.
   */

  const showMaterialInAllStickerPlanes = () => {
    const scene = sceneRef.current;
    const stickerPlanes = scene.children.filter((child) =>
      child.name.startsWith("planeMesh-")
    );
    stickerPlanes.forEach((plane) => {
      plane.material.visible = true;
    });
  };

  /**
   * LOAD ALL STICKERS IN TAB / SINGLE STICKER AT INDEX.
   */
  const loadTabsStickers = () => {
    // Load stickers from localStickersApplied.current
    localStickersApplied.current.forEach((sticker) => {
      loadSticker(sticker);
    });
  };

  /**
   * Sticker size
   */

  const loadSticker = (sticker) => {
    const scene = sceneRef.current;
    const planeMaterial = createStickerMaterial(sticker.image_path);

    const planeMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(22, 22),
      planeMaterial
    );

    // delta for position to be mutliplied with.
    // Keep this as small as possible to not see any
    // noticable z diffs bet. planes
    const delta = 0.2;

    planeMesh.position.set(
      sticker.x_pos,
      sticker.y_pos,
      sticker.z_render_order * delta
    );

    planeMesh.rotateZ(sticker.clockwise_rotatation);
    // planeMesh.rotateX(-Math.PI / 4)
    // planeMesh.rotateY(controlsRef.current.getPolarAngle())

    planeMesh.name = `planeMesh-${sticker.index}`; // Set a name to easily remove it later if needed

    planeMaterial.needsUpdate = true;
    planeMesh.renderOrder = 2;

    setTimeout(() => {
      scene.add(planeMesh);

      // check if redux sticker has a decal intersection point
      const redux_intersect_coordinates = sticker.decalIntersectionPoint;

      if (redux_intersect_coordinates) {
        planeMesh.material.visible = false;
        const weaponModelObject = scene.getObjectByName("stickerPlate");

        if (weaponModelObject) {
          const decal_intersect_vector = new THREE.Vector3(
            redux_intersect_coordinates.x,
            redux_intersect_coordinates.y,
            redux_intersect_coordinates.z
          );

          createStickerDecal(
            decal_intersect_vector,
            weaponModelObject.children[0],
            planeMesh,
            sticker.index,
            sticker.clockwise_rotatation,
            sticker
          );
        } else {
          console.warn("Weapon model object not found after delay.");
        }
      }
    }, 300); // Wait 1s before getting the weapon model object
  };


/**
 * MOUSE CONTROLS
 */

const onMouseDown = (event) => {

  const canvas = canvasRef.current;
  const camera = cameraRef.current;
  const canvasRect = canvas.getBoundingClientRect();

  const mouseX = ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1;
  const mouseY = -((event.clientY - canvasRect.top) / canvasRect.height) * 2 + 1;

  const mouse = new THREE.Vector2(mouseX, mouseY);
  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, camera);

  const scene = sceneRef.current;

  const allIntersects = raycaster.intersectObjects(scene.children.filter(
    (child) => child.name.startsWith("decal-") || child.name.startsWith("planeMesh-")
  ));

  // Sort intersects by distance from the camera
  allIntersects.sort((a, b) => a.distance - b.distance);

  // Function to extract the ID from the object name
  const getIdFromName = (name) => {
    const match = name.match(/-(\d+)$/);
    return match ? parseInt(match[1], 10) : -1; // Return -1 if no ID found
  };

  const isClickInsideCanvas =
    event.clientX >= canvasRect.left &&
    event.clientX <= canvasRect.right &&
    event.clientY >= canvasRect.top &&
    event.clientY <= canvasRect.bottom;

  // Left-click to select and move the closest intersected object
  if (allIntersects.length > 0) {
    toggleOrbitControls(false);

    const closestIntersect = allIntersects[0];
    const closestIntersectId = getIdFromName(closestIntersect.object.name);

    selectSticker(
      closestIntersect.object,
      closestIntersectId
    );
    isPlaneMoving.current = true;
  } else {
    if (isClickInsideCanvas) {
      removeAllStickerBorders();
      toggleOrbitControls(true);
      unselectSticker();
    }
  }

};







  const onMouseMove = (event) => {
    if (isPlaneMoving.current && localSelectedStickerIx.current !== -1) {
      // Retrieve the trashcan model by its name
      const trashModel = sceneRef.current.getObjectByName("trashcanModel");

      if (trashModel) {
        // Traverse through all children of the group
        trashModel.traverse((child) => {
          if (child.isMesh && child.material) {
            // Ensure the material is visible
            child.material.visible = true; // Make the material visible
          }
        });
      }

      toggleOrbitControls(false);

      const canvas = canvasRef.current;
      const camera = cameraRef.current;

      const canvasRect = canvas.getBoundingClientRect();
      const mouseX =
        ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1;
      const mouseY =
        -((event.clientY - canvasRect.top) / canvasRect.height) * 2 + 1;

      const mouse = new THREE.Vector3(mouseX, mouseY, 0);
      mouse.unproject(camera);

      const direction = mouse.sub(camera.position).normalize();
      const distance = -camera.position.z / direction.z;
      const position = camera.position
        .clone()
        .add(direction.multiplyScalar(distance));

      const scene = sceneRef.current;
      const selectedSticker = scene.children.find(
        (child) => child.name === `planeMesh-${localSelectedStickerIx.current}`
      );

      // if (selectedSticker) {
      //   selectedSticker.position.z = 0;

      //   selectedSticker.material.needsUpdate = true;
      // }

      selectedSticker.material.visible = true;

      // if intersect with weaponModel and stickerPlate, make border color green

      // if intersect with only weaponModel, make border red

      removeStickerDecal(localSelectedStickerIx.current);

      if (selectedSticker) {
        // Update only x and y coordinates
        selectedSticker.position.x = position.x;
        selectedSticker.position.y = position.y;
      }
    }
  };

  const onMouseUp = (event) => {
    const trashModel = sceneRef.current.getObjectByName("trashcanModel");
    setTimeout(() => {
      // Retrieve the trashcan model by its name

      if (trashModel) {
        // Traverse through all children of the group
        trashModel.traverse((child) => {
          if (child.isMesh && child.material) {
            // Ensure the material is not visible
            child.material.visible = false;
          }
        });
      }
    }, 0); // Delay in milliseconds


    const scene = sceneRef.current;
    isPlaneMoving.current = false;
    toggleOrbitControls(true);

    const selectedSticker = scene.children.find(
      (child) => child.name === `planeMesh-${localSelectedStickerIx.current}`
    );

    const existingDecal = scene.children.find(
      (child) => child.name === `decal-${localSelectedStickerIx.current}`
    );

    if (selectedSticker) {
      // Dispatch action to update sticker position in Redux state
      dispatch(
        updateStickerPosition({
          tabId: activeTab,
          stickerIndex: localSelectedStickerIx.current,
          xPos: selectedSticker.position.x,
          yPos: selectedSticker.position.y,
          zPos: selectedSticker.position.z,
        })
      );
    }

    if (!existingDecal && selectedSticker) {
      const rotation_angle = selectedSticker.rotation._z;

      const canvas = canvasRef.current;

      const canvasRect = canvas.getBoundingClientRect();
      const mouseX =
        ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1;
      const mouseY =
        -((event.clientY - canvasRect.top) / canvasRect.height) * 2 + 1;

      raycastFromPlaneToWeaponModel(
        selectedSticker,
        localSelectedStickerIx.current,
        rotation_angle,
        mouseX,
        mouseY
      );

      raycastFromPlaneToTrashcan(
        selectedSticker,
        localSelectedStickerIx.current,
      );
    }

    // calculate distance to sticker slot 0 for all applied stickers.

    console.log(localWeaponModel.current);
    console.log(localAllStickers.current);

    localAllStickers.current.forEach((sticker, index) => {
      const x_value = sticker.x_pos;
      const y_value = sticker.y_pos;


      const delta_x = x_value - localWeaponModel.current.sticker_slot_x;
      const delta_y = y_value - localWeaponModel.current.sticker_slot_y;

      // mirror both to match cs2
      const mirror_delta_y = -1 * delta_y;

      const multiplier = 0.0085;

      const cs2_adjusted_delta_x = delta_x * multiplier;
      const cs2_adjusted_delta_y = mirror_delta_y * multiplier;

      console.log(`Δx=${cs2_adjusted_delta_x}, Δy=${cs2_adjusted_delta_y}`);
      console.log("Sticker_id: ", sticker.sticker_id); // for each sticker
      console.log("Rotation:: ", sticker.clockwise_rotatation); // for each sticker

    });
  };

  const removeStickerWithTrashcan = (sticker_ix) => {

    dispatch(
      removeSticker({
        tabId: activeTab,
        stickerIndex: sticker_ix
      })
    );

    dispatch(requestRemoveSelectedSticker());

    dispatch(requestStickerReload());

    // remove sticker
    removeStickerDecal(sticker_ix);
    // remove decal
    removeStickerPlane(sticker_ix);
  };

  const raycastFromPlaneToTrashcan = (
    selectedSticker,
    sticker_ix,
  ) => {
    const cameraPosition = cameraRef.current.position.clone();
    const planeCenter = selectedSticker.position.clone();

    // Calculate the direction from the camera to the plane center
    const rayDirection = planeCenter.sub(cameraPosition).normalize();

    // Create a Raycaster that starts from the camera position and points in the direction of the rayDirection
    const raycaster = new THREE.Raycaster(cameraPosition, rayDirection);

    const floatingTrashCan = sceneRef.current.getObjectByName("trashcanPlane");

    const scene = sceneRef.current;
    const stickerPlane = scene.children.find(
      (child) => child.name === `planeMesh-${localSelectedStickerIx.current}`
    );

    if (floatingTrashCan) {
      const intersects = raycaster.intersectObject(floatingTrashCan, true);

      if (intersects.length > 0) {
        // Sort intersections by distance from the ray origin (camera)
        intersects.sort((a, b) => a.distance - b.distance);

        // Get the closest intersection point
        const closestIntersection = intersects[0];

        const intersectionPoint = closestIntersection.point;

        removeStickerWithTrashcan(sticker_ix)
      }
    }
  };

  const raycastFromPlaneToWeaponModel = (
    selectedSticker,
    sticker_ix,
    rotation_angle,
    mouseX,
    mouseY
  ) => {
    const cameraPosition = cameraRef.current.position.clone();
    const planeCenter = selectedSticker.position.clone();

    // Calculate the direction from the camera to the plane center
    const rayDirection = planeCenter.sub(cameraPosition).normalize();

    // Create a Raycaster that starts from the camera position and points in the direction of the rayDirection
    const raycaster = new THREE.Raycaster(cameraPosition, rayDirection);

    const stickerPlate = sceneRef.current.getObjectByName("stickerPlate");

    const scene = sceneRef.current;
    const stickerPlane = scene.children.find(
      (child) => child.name === `planeMesh-${localSelectedStickerIx.current}`
    );

    const old_z = stickerPlane.position.z;

    if (stickerPlate) {
      const intersects = raycaster.intersectObject(stickerPlate, true);

      if (intersects.length > 0) {
        // Sort intersections by distance from the ray origin (camera)
        intersects.sort((a, b) => a.distance - b.distance);

        // Get the closest intersection point
        const closestIntersection = intersects[0];

        const intersectionPoint = closestIntersection.point;
        const intersectionNormal = closestIntersection.normal;
        const intersection_obj = closestIntersection.object;

        const saved_intersect = new THREE.Vector3(
          67.68193303075996,
          33.73516677045865,
          -16.915223540996184
        );

        // Hide the plane's material (optional)
        selectedSticker.material.visible = false;

        // Create decal at the intersection point
        createStickerDecal(
          intersectionPoint,
          intersection_obj,
          selectedSticker,
          sticker_ix,
          rotation_angle
        );


        stickerPlane.position.x = intersectionPoint.x;
        stickerPlane.position.y = intersectionPoint.y;
        // if (tabs[activeTab].stickers_applied[localSelectedStickerIx.current]) {
        //   stickerPlane.position.z = intersectionPoint.z + tabs[activeTab].stickers_applied[localSelectedStickerIx.current].z_render_order;
        // }
      } else {
        // Show the plane's material when no intersection occurs
        removeStickerDecal(sticker_ix);
        dispatch(
          setDecalIntersectionPoint({
            tabId: activeTab,
            stickerIndex: sticker_ix,
            intersectionPoint: null,
          })
        );

        // stickerPlane.position.z = old_z;
      }
    }
  };

  function createStickerDecal(
    intersectionPoint,
    intersection_obj,
    selectedSticker,
    sticker_ix,
    rotation_angle,
    sticker
  ) {
    const scene = sceneRef.current;

    const sticker_image_path =
      selectedSticker.material.uniforms.textureMap.value.source.data.currentSrc;

    const textureLoader = new THREE.TextureLoader();
    const decalDiffuse = textureLoader.load(sticker_image_path);

    decalDiffuse.colorSpace = THREE.SRGBColorSpace;

    const decalMaterial = createDecalMaterial(decalDiffuse);

    // Create decal geometry with rotation
    const rotation = new THREE.Euler(0, 0, rotation_angle); // Rotate 90 degrees around Z-axis
    const decalGeometry = new DecalGeometry(
      intersection_obj, // Target mesh to receive the decal
      intersectionPoint, // Position of the decal
      rotation,
      new THREE.Vector3(22, 22, 22) // Size of the decal
    );

    // Create decal mesh
    const decalMesh = new THREE.Mesh(decalGeometry, decalMaterial);
    decalMesh.name = `decal-${sticker_ix}`; // Set a name to easily remove it later if needed
    decalMaterial.needsUpdate = true;

    decalMesh.renderOrder = 1; // Set render order to a higher value than the model

    const delta = 0.2;

    // decalMesh.position.set(
    //   sticker.x_pos,
    //   sticker.y_pos,
    //   sticker.z_render_order * delta
    // );


    // console.log(decalMesh);
    // const redux_sticker = tabs[activeTab].stickers_applied[sticker_ix];
    // if (redux_sticker) {
    //   if (redux_sticker.z_render_order > 0) {
    //     decalMesh.position.z += redux_sticker.z_render_order * delta;
    //   }
    // }

    // Add decal mesh to the scene
    scene.add(decalMesh);



    dispatch(
      setDecalIntersectionPoint({
        tabId: activeTab,
        stickerIndex: sticker_ix,
        intersectionPoint: {
          x: intersectionPoint.x,
          y: intersectionPoint.y,
          z: intersectionPoint.z,
        },
      })
    );

    dispatch(requestStickerReload());
  }

  /**
   * REMOVE PLANE / ALL PLANES FROM SCENE
   */
  function removeStickerPlane(sticker_ix) {
    const scene = sceneRef.current;
    const planeName = `planeMesh-${sticker_ix}`;
    const plane = scene.getObjectByName(planeName);
    if (plane) {
      scene.remove(plane);
    }
  }

  const removeAllStickerPlanes = () => {
    const scene = sceneRef.current;
    const stickerPlanes = scene.children.filter((child) =>
      child.name.startsWith("planeMesh-")
    );
    stickerPlanes.forEach((plane) => {
      scene.remove(plane);
      plane.geometry.dispose();
      plane.material.dispose();
    });
  };

  /**
   * REMOVE DECAL / ALL DECALS FROM SCENE
   */

  function removeStickerDecal(sticker_ix) {
    const scene = sceneRef.current;
    const decalName = `decal-${sticker_ix}`;
    const decal = scene.getObjectByName(decalName);
    if (decal) {
      scene.remove(decal);
    }
  }

  const removeAllStickerDecals = () => {
    const scene = sceneRef.current;
    const decals = scene.children.filter((child) =>
      child.name.startsWith("decal-")
    );
    decals.forEach((decal) => {
      scene.remove(decal);
      decal.geometry.dispose();
      decal.material.dispose();
    });
  };

  /**
   * SELECT / UNSELECT STICKER
   */
  const selectSticker = (stickerPlaneMesh, stickerIndex) => {
    // addStickerBorder(stickerPlaneMesh, 0xffffff);

    localSelectedStickerIx.current = stickerIndex;

    dispatch(
      setSelectedStickerIndex({
        tabId: activeTab,
        selectedIndex: localSelectedStickerIx.current,
      })
    );

  };

  const unselectSticker = (stickerPlaneMesh) => {
    if (stickerPlaneMesh) {
      // removeStickerBorder(stickerPlaneMesh);
    }
    dispatch(
      setSelectedStickerIndex({
        tabId: activeTab,
        selectedIndex: -1,
      })
    );
    localSelectedStickerIx.current = -1;
  };

  const toggleOrbitControls = (state) => {
    controlsRef.current.enabled = state;
  };

  /**
   * STICKER BORDERS
   */
  const addStickerBorder = (stickerPlaneMesh, color = 0xffffff) => {
    const scene = sceneRef.current;

    // Remove existing borders from all other stickers
    scene.children.forEach((child) => {
      if (child !== stickerPlaneMesh && child.border) {
        removeStickerBorder(child);
      }
    });

    // Add a border to the specified sticker mesh
    if (!stickerPlaneMesh.border) {
      // Create a geometry that simulates a thicker border
      const borderGeometry = new THREE.EdgesGeometry(stickerPlaneMesh.geometry);

      // Increase border thickness by creating a mesh with a thick line
      const borderMaterial = new THREE.LineBasicMaterial({
        color: color, // Set color of the border line
        linewidth: 1.5, // Set thickness of the border line
      });

      // Create the border as a LineSegments
      const border = new THREE.LineSegments(borderGeometry, borderMaterial);

      // Add border to the sticker mesh
      stickerPlaneMesh.border = border;
      stickerPlaneMesh.add(border);
    }
  };

  const removeStickerBorder = (stickerPlaneMesh) => {
    if (stickerPlaneMesh.border) {
      stickerPlaneMesh.remove(stickerPlaneMesh.border);
      stickerPlaneMesh.border.geometry.dispose();
      stickerPlaneMesh.border.material.dispose();
      stickerPlaneMesh.border = null;
    }
  };

  const removeAllStickerBorders = () => {
    const scene = sceneRef.current;

    scene.children.forEach((child) => {
      if (child.border) {
        removeStickerBorder(child);
      }
    });
  };

  /**
   * CONTEXT (RIGHT CLICK MENU)
   */

  // Function to open custom context menu
  function openSelectedRightClickContextMenu(
    clientX,
    clientY,
    selectedStickerIndex
  ) {
    setContextMenuPosition({
      x: clientX - 420,
      y: clientY,
      selectedStickerIndex,
    });
  }

  function openUnSelectedRightClickContextMenu(clientX, clientY) {
    setContextMenuPosition({
      x: clientX - 420,
      y: clientY,
    });
  }

  const closeContextMenu = () => {
    setContextMenuPosition(null);
  };

  const handleRemoveSticker = (sticker_ix) => {

    console.log("removed sticker: ", sticker_ix)
    dispatch(
      changeStickerRenderOrder({
        tabId: activeTab,
        stickerIndex: sticker_ix,
        moveType: "front",
      })
    );
    localSelectedStickerIx.current = -1;

    // // update redux state.
    if (sticker_ix && sticker_ix !== 0) {
      dispatch(
        removeSticker({
          tabId: activeTab,
          stickerIndex: sticker_ix - 1 + 1,
        })
      );
      // remove sticker
      removeStickerDecal(sticker_ix);
      // remove decal
      removeStickerPlane(sticker_ix);
    }
    //dispatch(requestStickerReload())
  };

  /**
   * CLEAR CANVAS
   */
  const handleClearCanvas = () => {
    dispatch(removeAllStickersInTab({ tabId: activeTab }));
    dispatch(requestCanvasClearing());
  };

  const handlePasteHere = () => {
    dispatch(requestStickerPaste());
  };


  const handleRotateSticker = (sticker_ix, rotation_angle) => {
    /**
     * 1) Dispatch rotation angle to redux
     * 2) Request sticker update
     */

    dispatch(
      rotateSticker({
        tabId: activeTab,
        stickerIndex: sticker_ix,
        rotation: rotation_angle,
      })
    );

    removeStickerDecal(sticker_ix);

    const scene = sceneRef.current;
    const planeName = `planeMesh-${sticker_ix}`;
    const plane = scene.getObjectByName(planeName);
    if (plane) {
      plane.material.visible = true;
      // raycastFromPlaneToWeaponModel(plane, sticker_ix, rotation_angle)
    }

    dispatch(requestStickerReload());
  };

  const handleAddStickerToCart = (sticker_ix) => {
    const redux_sticker = tabs[activeTab].stickers_applied[sticker_ix];

    const name = redux_sticker.name;
    const image_path = redux_sticker.image_path;
    const internal_item_id = redux_sticker.internal_item_id;

    /* addToCart({ image_src, name, price }); */
    dispatch(
      setPopupData({
        name: name,
        internal_id: internal_item_id,
        image_path: image_path,
      })
    );
    dispatch(makeStockPopupVisible());
  };

  const handleChangeZIndex = (sticker_ix, pos) => {

    switch (pos) {
      case "forward":
        dispatch(
          changeStickerRenderOrder({
            tabId: activeTab,
            stickerIndex: sticker_ix,
            moveType: "forward",
          })
        );
        break; // Add break here
      case "front":
        dispatch(
          changeStickerRenderOrder({
            tabId: activeTab,
            stickerIndex: sticker_ix,
            moveType: "front",
          })
        );
        break; // Add break here
      case "back":
        dispatch(
          changeStickerRenderOrder({
            tabId: activeTab,
            stickerIndex: sticker_ix,
            moveType: "back",
          })
        );
        break; // Add break here
      case "backward":
        dispatch(
          changeStickerRenderOrder({
            tabId: activeTab,
            stickerIndex: sticker_ix,
            moveType: "backward",
          })
        );
        break; // Add break here
      default:
        break;
    }

    dispatch(requestStickerReload());
  };

  const handleCopySticker = (sticker_ix) => {
    dispatch(
      setCopiedSticker({
        tabId: activeTab,
        stickerIndex: sticker_ix,
      })
    );
  };

  const handlePasteSticker = () => {
    dispatch(requestStickerPaste());
  };

  const handleDuplicateSticker = (sticker_ix) => {
    /**
     * Copy
     */
    handleCopySticker(sticker_ix);

    /**
     * Paste
     */
    handlePasteSticker();
  };


  const handleRightClickMenuCellClick = (cellText) => {
    const sticker_ix = contextMenuPosition.selectedStickerIndex;
    const canvas = canvasRef.current;
    switch (cellText) {
      case "Add to cart":
        handleAddStickerToCart(sticker_ix);
        break;
      case "Remove from canvas":
        handleRemoveSticker(sticker_ix);
        break;
      case "Copy":
        handleCopySticker(sticker_ix);
        break;
      case "Duplicate":
        handleDuplicateSticker(sticker_ix);

        // duplicate_sticker();
        break;
      case "Bring Forward":
        handleChangeZIndex(sticker_ix, "forward");
        break;
      case "Bring to Front":
        handleChangeZIndex(sticker_ix, "front");
        break;
      case "Bring Back":
        handleChangeZIndex(sticker_ix, "backward");
        break;
      case "Bring to Back":
        handleChangeZIndex(sticker_ix, "back");
        break;
      case "Rotate 45° Left":
        handleRotateSticker(sticker_ix, Math.PI / 4);
        break;
      case "Rotate 90° Left":
        handleRotateSticker(sticker_ix, Math.PI / 2);
        break;
      case "Rotate 45° Right":
        handleRotateSticker(sticker_ix, -Math.PI / 4);
        break;
      case "Rotate 90° Right":
        handleRotateSticker(sticker_ix, -Math.PI / 2);
        break;

      /**
       * CASES FOR UN-SELECTED
       */

      case "Clear Stickers":
        handleClearCanvas();
        break;

      case "Paste":
        if (clip_board.copied_sticker !== null) {
          handlePasteHere();
        }
        break;

      case "Organize":
        handleOrganizeStickers();
        break;

      case "Hide Weaponmodel":
        handleHideWeaponModel();
        break;

      default:
        break;
    }
    closeContextMenu();
  };

  const handleKeyDown = (event) => {
    // remove sticker
    if (event.key === "Delete") {
      if (localSelectedStickerIx.current !== -1) {
        handleRemoveSticker(localSelectedStickerIx.current);
      }
    }

    // COPY + PASTE
    if ((event.metaKey || event.ctrlKey) && event.key === "c") {
      event.preventDefault(); // Prevent the default copy action
      if (localSelectedStickerIx.current) {
        handleCopySticker(localSelectedStickerIx.current);
      }
    }
    if ((event.metaKey || event.ctrlKey) && event.key === "v") {
      event.preventDefault(); // Prevent the default copy action
      handlePasteHere();
    }

    // ROTATE
    // rotations
    if (event.key === "ArrowRight") {
      handleRotateSticker(localSelectedStickerIx.current, -Math.PI / 32);
    }

    if (event.key === "ArrowLeft") {
      handleRotateSticker(localSelectedStickerIx.current, Math.PI / 32);
    }

    // z index
    if (event.key === "ArrowUp") {
      handleChangeZIndex(localSelectedStickerIx.current, "forward");
    }

    if (event.key === "ArrowDown") {
      handleChangeZIndex(localSelectedStickerIx.current, "backward");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        marginTop: "3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <SignInPopupModal /> */}
      <div
        style={{
          zIndex: 0,
          pointerEvents: "auto", // Disable pointer events for WatermarkGrid
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            overflow: "hidden",
          }}
        />
      </div>

      <div
        style={{
          zIndex: 0,
          pointerEvents: "auto", // Disable pointer events for WatermarkGrid
        }}
      >
        <BottomBar />
        <HelpSection />



      </div>
      {contextMenuPosition && contextMenuPosition.selectedStickerIndex && (
        <SelectedRightClickMenu
          x={contextMenuPosition.x}
          y={contextMenuPosition.y}
          onCellClick={handleRightClickMenuCellClick}
        />
      )}
      {contextMenuPosition && !contextMenuPosition.selectedStickerIndex && (
        <UnSelectedRightClickMenu
          x={contextMenuPosition.x}
          y={contextMenuPosition.y}
          onCellClick={handleRightClickMenuCellClick}
        />
      )}
    </div>
  );
};

export default GraphicsScene;
