
import * as THREE from 'three';

import {
  createStickerMaterial,
  createWeaponMaterial,
  createScopeMaterial
} from "./ThreeMaterials"; // Import shader material functions

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

const textureLoader = new THREE.TextureLoader();
const objLoader = new OBJLoader();


export const loadTrashCan = (scene) => {

  const existingTrashcanPlane = scene.getObjectByName("trashcanPlane");
  const existingTrashcanModel = scene.getObjectByName("trashcanModel");
  // cleanup
  if (
    existingTrashcanPlane || existingTrashcanModel
  ) {
    scene.remove(existingTrashcanPlane);
    scene.remove(existingTrashcanModel);
  }

  // Load the weapon model
  objLoader.load(
    "images/objects/Trashcan/trash_can.obj",
    (object) => {
      object.rotation.x = Math.PI / 6;
      object.scale.set(30, 30, 30);

      // Center the object
      const boundingBox = new THREE.Box3().setFromObject(object);
      const center = boundingBox.getCenter(new THREE.Vector3());
      object.position.sub(center);

      // Adjust position
      object.position.x += 0;
      object.position.y += 60;
      object.position.z = -25;

      // yes we use scopetexture for trash can as well :)
      const scopetexture = textureLoader.load(
        "images/objects/shared_scope/shared_scope_vmat_g_tcolor_b1605615.png"
      );

      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          // Apply material with transparency
          child.material = createScopeMaterial({
            scopetexture,
          });

          // Make the material transparent
          child.material.visible = false;
         
        }
      });

      object.name = "trashcanModel";
      scene.add(object);
    },
    (xhr) => { },
    (error) => {
      console.error("Error loading OBJ file:", error);
    }
  );


  // Create and add the plane with a transparent red color
  const planeGeometry = new THREE.PlaneGeometry(180, 108); // Adjust size as needed
  planeGeometry.scale(0.1, 0.2, 1);

  const planeMaterial = new THREE.MeshBasicMaterial({
    color: 0xff0000, // Red color
    transparent: true, // Enable transparency
    opacity: 0.0, // Fully transparent (adjust this value as needed)
  });

  const plane = new THREE.Mesh(planeGeometry, planeMaterial);

  // Adjust position based on your previous setup
  plane.position.set(0, 60, -35); // Position the plane
  plane.name = "trashcanPlane";

  scene.add(plane);


};
