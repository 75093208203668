import React, { useEffect, useRef, useState } from "react";
import "../../../App.css";
import "./ThisCraftBar.css";
import SelectWeaponField from "./SelectWeaponField";
import { useCart } from "../../TopBar/cart/CartContext";
import { useDispatch, useSelector } from "react-redux";
import {
    requestStickerSelection,
    setSelectedStickerIndex,
    removeSticker,
    requestStickerReload,
    removeAllStickersInTab,
    verifyCanvasClearing,
    requestCanvasClearing,
    changeStickerRenderOrder,
    requestRemoveSelectedSticker,
} from "../../../redux/slices/tabSystemSlice";
import {
    addItem,
    makeStockPopupVisible,
    removeItem,
} from "../../../redux/slices/cartSessionSlice";

import { setPopupData } from "../../../redux/slices/popupDateSlice";



const calculateDisplayCurrencyPrice = (price, user) => {

    // Check if user is logged in
    if (Object.keys(user).length > 0) {
        var steam_price = (price * user.currency_multiplier).toFixed(2);

        if (price !== 0) {
            return (steam_price * user.currency_multiplier).toFixed(2).toString();
        } else {
            return "?";
        }
    } else {
        return "EUR " + price.toString();
    }

};

const SelectedStickerBox = ({
    sticker,
    addStickerToCart,
    removeStickerFromCanvas,
}) => {
    function radiansToDegrees(radians) {
        const degrees = radians * (180 / Math.PI);
        return degrees;
    }

    const dispatch = useDispatch();
    const redux_cart = useSelector((state) => state.cart_session);
    const user = useSelector((state) => state.user);

    const addStickerToReduxCart = () => {
        dispatch(
            setPopupData({
                name: sticker.name,
                internal_id: sticker.internal_item_id,
                image_path: sticker.image_path,
            })
        );
        dispatch(makeStockPopupVisible());
    };

    return (
        <div className="selected-sticker-box">
            <div className="top-box-selected-sticker">
                <div className="image-box-selected-sticker">
                <img
                    src={sticker.image_path}
                    style={{
                        width: "70px",
                        height: "70px",
                        marginLeft: "0px",
                        marginTop: "0px",
                        objectFit: "cover",
                        objectPosition: "center",

                        /* Prevent dragging images */
                        "-webkit-user-drag": "none", /* Prevent image drag in WebKit browsers */
                        "user-drag": "none",         /* Prevent image drag in other browsers */

                        /* Prevent interaction (no clicking, no dragging) */
                        "pointer-events": "none",    /* Disable interaction with the image itself */
                    }}
                    alt="Unselected sticker"
                />
                </div>
                <div className="info-box-selected-sticker">
                    <p className="selected-sticker-name">{sticker.name}</p>

                    {redux_cart.cart_enabled ? (
                        sticker.listing_id != null ? (
                            <div>
                                <p className="selected-sticker-price">
                                    SEK{" "}
                                    {/* {calculateDisplayCurrencyPrice(sticker.price, user)} */}
                                </p>

                                {sticker.price < sticker.steam_price ? (
                                    <div className="discountbox-thiscraft">
                                        <p>
                                            {"-" +
                                                (
                                                    (1 - sticker.price / sticker.steam_price) *
                                                    100
                                                ).toFixed(0) +
                                                "%"}{" "}
                                        </p>
                                    </div>
                                ) : (
                                    <div />
                                )}

                                {redux_cart.checkout_stage <= 2 ? (
                                    <button
                                        className="selected-sticker-add-to-cart"
                                        onClick={addStickerToReduxCart}
                                    >
                                        ADD TO CART
                                    </button>
                                ) : (
                                    <div style={{ marginBottom: "16px" }}></div>
                                )}
                            </div>
                        ) : (
                            <div className="selected-sticker-out-of-stock-banner">
                                OUT OF STOCK
                            </div>
                        )
                    ) : (
                        <div>
                            <p className="selected-sticker-price">
                                Steam Price: {user.display_currency}{" "}
                                {calculateDisplayCurrencyPrice(sticker.steam_price, user)}

                            </p>
                        </div>
                    )}
                </div>
            </div>

            <div className="bottom-box-selected-sticker">
                <div className="coordinate-box-selected-sticker">
                    <div className="coordinate-left-side">
                        <div className="coordinate-left-side-top">
                            <p
                                className="x-coordinate-left-label"
                                style={{ color: "lightgray", fontWeight: 800 }}
                            >
                                X
                            </p>
                            <p className="x-coordinate-right-value">
                                {Math.round(sticker.x_pos)}
                            </p>
                        </div>
                        <div className="coordinate-left-side-bottom">
                            <p
                                className="rotation-coordinate-left-label"
                                style={{ color: "lightgray", fontWeight: 800 }}
                            >
                                R
                            </p>
                            <p className="rotation-coordinate-right-value">
                                {Math.ceil(
                                    radiansToDegrees(sticker.clockwise_rotatation) * 10
                                ) / 10}
                                °
                            </p>
                        </div>
                    </div>
                    <div className="coordinate-right-side">
                        <div className="coordinate-right-side-top">
                            <p
                                className="y-coordinate-left-label"
                                style={{ color: "lightgray", fontWeight: 800 }}
                            >
                                Y
                            </p>
                            <p className="y-coordinate-right-value">
                                {Math.round(sticker.y_pos)}
                            </p>
                        </div>
                        <div className="coordinate-right-side-bottom">
                            <p
                                className="z-coordinate-left-label"
                                style={{ color: "lightgray", fontWeight: 800 }}
                            >
                                Z
                            </p>
                            <p className="z-coordinate-right-value">
                                {Math.round(sticker.z_render_order)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="remove-canvas-wrapper">
                <button
                    className="selected-sticker-remove-from-canvas"
                    onClick={removeStickerFromCanvas}
                >
                    REMOVE FROM CANVAS
                </button>
            </div>
        </div>
    );
};

export default SelectedStickerBox