import React, { useEffect } from "react";
import "../../../App.css";
import "./StickerSearch.css";

import { useDispatch, useSelector } from "react-redux";
import {
  addSticker,
  requestStickerSelection,
} from "../../../redux/slices/tabSystemSlice";
import {
  addItem,
  makeStockPopupVisible,
  removeItem,
} from "../../../redux/slices/cartSessionSlice";
import { setPopupData } from "../../../redux/slices/popupDateSlice";
import { setStickerAddedID, clearStickerID } from "../../../redux/slices/stickerFetchingSlice";

const StickerResultCell = ({
  id,
  image_path,
  name,
  steam_price,
  price,
  quantity,
  listing_id,
  internal_item_id,
}) => {
  const activeTab = useSelector((state) => state.tab_system.active_tab);
  const redux_cart = useSelector((state) => state.cart_session);
  const user = useSelector((state) => state.user);
  const sticker_fetching = useSelector((state) => state.sticker_fetching);

  const dispatch = useDispatch();

  const handleClick = () => {

    console.log("6591 -- ", id)

    dispatch(
      addSticker({
        tabId: activeTab,
        sticker: {
          listing_id: listing_id,
          internal_item_id: internal_item_id,
          name: name,
          price: price,
          steam_price: steam_price,
          quantity: quantity,
          image_path: image_path,
          x_pos: 0,
          y_pos: 0,
          z_pos: 0,
          clockwise_rotatation: 0,
          decalIntersectionPoint: null,
          has_been_removed: false,
          is_locked: false,
          sticker_id: id,

          // old z render order
          z_render_order: 0,

        },
      })
    );


    // update priority system slice
    dispatch(setStickerAddedID(id));


    const search_query_performed = sticker_fetching.search_query_performed;
    const added_id = sticker_fetching.sticker_added_id;

    // if a search query is applied
    if (search_query_performed == true) {
      updateStatisticsAPICall(id)
    }


  };

  const updateStatisticsAPICall = async (sticker_id) => {
    console.log("API Call for sticker:", sticker_id);

    try {
      // Optional delay (document its purpose if needed)
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Make the API call
      const response = await fetch(process.env.REACT_APP_API_URL + "auth/AppliedSticker", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          itemId: sticker_id, // Fixed typo
        }),
      });

      // Check for non-2xx response codes
      if (!response.ok) {
        throw new Error(`Failed to send request: ${response.status} ${response.statusText}`);
      }

      // Parse and use response data if needed
      const data = await response.json();
      console.log("API Response Data:", data);

      return data; // Return the parsed response (optional)

    } catch (error) {
      console.error("Error during API call:", error);
      throw error; // Re-throw the error to notify the caller (optional)

    } finally {
      // Perform any necessary cleanup if needed
      console.log("API call finished for sticker:", sticker_id);
    }
  };



  const addStickerToReduxCart = () => {
    /* dispatch(
      addItem({
        item_name: name,
        item_price: price,
        item_image_src: image_path,
        listing_id: listing_id,
      })
    ); */

    dispatch(
      setPopupData({
        name: name,
        internal_id: internal_item_id,
        image_path: image_path,
      })
    );
    dispatch(makeStockPopupVisible());
  };

  /* const handleAddToCart = () => {
    const image_src = new Image();
    image_src.src = image_path;
    addToCart({ id, image_src, name, price });
  }; */

  const calculateDisplayCurrencyPrice = () => {
    return (price * user.currency_multiplier).toFixed(2);
  };

  const calculateDisplayCurrencySteamPrice = (price, user) => {

    // Check if user is logged in
    if (Object.keys(user).length > 0) {
      var steam_price = (price * user.currency_multiplier).toFixed(2);

      if (price !== 0) {
        return (steam_price * user.currency_multiplier).toFixed(2).toString();
      } else {
        return "?";
      }
    } else {
      return "EUR " + price.toString();
    }

  };

  return (
    <div
      className="result-cell"
      style={{
        height: "155px",
        width: "100%",
        position: "relative",
        marginBottom: "3px",
        display: "flex",
        alignItems: "center",
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      {/* Left Picture */}
      <div
        style={{
          width: "100px",
          height: "100px",
          overflow: "hidden",
          flexShrink: 0,
        }}
      >
        <img
          src={image_path}
          alt="Product"
          style={{
            width: "80%",
            height: "80%",
            marginLeft: "8px",
            marginTop: "6px",
            objectFit: "cover",
            objectPosition: "center",
            userSelect: "none", // Prevent selection
            pointerEvents: "none", // Disable interaction (like clicks, hover, etc.)
            draggable: "false", // Disable dragging
          }}
        />

      </div>

      {/* Right Content Stack */}
      <div style={{ position: "absolute", top: 0, left: "100px", zIndex: 1 }}>
        {/* Title */}

        {redux_cart.cart_enabled ? (
          <p
            style={{
              fontWeight: "bold",
              marginBottom: "5px",
              color: "white",
              fontSize: "10px",
              marginRight: "10px",
              paddingTop: "25px",
            }}
          >
            {name}
          </p>
        ) : (
          <p
            style={{
              fontWeight: "bold",
              marginBottom: "5px",
              color: "white",
              fontSize: "11px",
              marginRight: "10px",
              paddingTop: "40px",
            }}
          >
            {name}
          </p>
        )}

        {redux_cart.cart_enabled ? (
          <div>
            {listing_id != null ? (
              <div>
                {/* Price */}
                <p
                  style={{ color: "#fff", fontSize: "10px", fontWeight: "800" }}
                >
                  {/* From {user.display_currency} {calculateDisplayCurrencyPrice()} */}
                  from EUR 1121
                </p>
              </div>
            ) : (
              <div />
            )}
          </div>
        ) : (
          <div>
            <div>
              {/* Price */}
              <p style={{ color: "#fff", fontSize: "10px", fontWeight: "800", marginRight: "8px" }}>
                Steam Price {user.display_currency}{" "}
                {calculateDisplayCurrencySteamPrice(steam_price, user)}
              </p>
            </div>

            <div />
          </div>
        )}

        {redux_cart.cart_enabled ? (
          <div>
            {quantity == null ? (
              <>
                <p
                  style={{
                    color: "#FF2B2B",
                    fontSize: "10px",
                    marginTop: "10px",
                    fontWeight: "600",
                  }}
                >
                  Out of stock
                </p>
              </>
            ) : (
              <>
                {price < steam_price ? (
                  <>
                    <div className="discountbox">
                      {" "}
                      <p>
                        {"-" + ((price / steam_price) * 100).toFixed(1) + "%"}{" "}
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <p
                  style={{
                    color: "lightgray",
                    fontSize: "10px",
                    marginTop: "2px",
                  }}
                >
                  QTY: {quantity}
                </p>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {/* Add to Cart Button */}

      {redux_cart.cart_enabled &&
        listing_id != null &&
        redux_cart.checkout_stage <= 2 ? (
        <div
          className="add-to-cart-wrapper"
          style={{ position: "absolute", bottom: 0, right: 0, zIndex: 8 }}
          onClick={addStickerToReduxCart}
        >
          <button className="add-to-cart-button">
            <img
              src="images/cart.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </button>
        </div>
      ) : (
        <div
          className="add-to-cart-wrapper"
          style={{ position: "absolute", bottom: 0, right: 20, zIndex: 8 }}
        >
          <button className="add-to-canvas-button">
            APPLY
          </button>
        </div>
      )}
    </div>
  );
};

export default StickerResultCell;
