import React, { useEffect, useState } from "react";
import "../../../App.css";
import "./ThisCraftBar.css";
import SelectWeaponField from "./SelectWeaponField";
import { useCart } from "../../TopBar/cart/CartContext";
import { useDispatch, useSelector } from "react-redux";
import {
  removeSticker,
  requestStickerReload,
  removeAllStickersInTab,
  requestCanvasClearing,
  requestRemoveSelectedSticker,
  setStickerRenderOrder,
} from "../../../redux/slices/tabSystemSlice";
import { addItem } from "../../../redux/slices/cartSessionSlice";

import UnselectedStickerBox from "./UnselectedStickerBox";
import SelectedStickerBox from "./SelectedStickerBox";

// DropBar Component - updated to show only while dragging
const DropBar = ({ isDragging }) => {
  return (
    <div
      className="drop-bar"
      style={{
        backgroundColor: isDragging ? "#29293d" : "transparent", // Show bars only when dragging
        transition: "background-color 0.5s ease", // Smooth transition for color change
        display: isDragging ? "block" : "none", // Hide the bars when not dragging
      }}
    ></div>
  );
};

const ThisCraftBar = () => {
  const weapon_skins = require("./skin_data.json");

  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.tab_system.tabs);
  const activeTab = useSelector((state) => state.tab_system.active_tab);
  const appliedStickers = tabs[activeTab].stickers_applied;
  const selectedStickerIndex = tabs[activeTab].selected_sticker_index;
  const selected_sticker = appliedStickers[selectedStickerIndex];

  const [canvasStickers, setCanvasStickers] = useState([]);
  const [draggedStickerIndex, setDraggedStickerIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false); // Track drag status

  const { addToCart } = useCart();

  // Re-sort the stickers every time appliedStickers changes in Redux
  useEffect(() => {
    const sortedStickers = [...appliedStickers]
      .filter((sticker) => !sticker.has_been_removed)
      .sort((a, b) => b.z_render_order - a.z_render_order); // Sort by z_render_order descending

    setCanvasStickers(sortedStickers); // Update local state with the sorted stickers
  }, [appliedStickers]);

  const addStickerToCart = () => {
    if (selected_sticker) {
      const { id, name, price, image_path } = selected_sticker;
      addToCart({ id, image_src: image_path, name, price });
    }
  };

  const removeStickerFromCanvas = () => {
    dispatch(
      removeSticker({
        tabId: activeTab,
        stickerIndex: selected_sticker.index,
      })
    );
    dispatch(requestRemoveSelectedSticker());
    dispatch(requestStickerReload());
  };

  const clearCanvas = () => {
    console.log("CLEAR CANVAS FROM SIDEBAR");
    dispatch(removeAllStickersInTab({ tabId: activeTab }));
    dispatch(requestCanvasClearing());
  };

  // Drag and Drop Handlers
  const handleDragStart = (index) => {
    setDraggedStickerIndex(index);
    setIsDragging(true); // Start dragging
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };

  const handleDragEnd = () => {
    setIsDragging(false); // Reset drag state if the drag ends without a drop
  };

  const handleDrop = (index) => {
      
    if (draggedStickerIndex !== null && draggedStickerIndex !== index) {
      const updatedStickers = [...canvasStickers];
      const [draggedSticker] = updatedStickers.splice(draggedStickerIndex, 1); // Remove the dragged sticker

      if (!draggedSticker) {
        console.error("Dragged sticker not found");
        return;
      }

      // Insert the dragged sticker at the new index (where the DropBar is)
      updatedStickers.splice(index, 0, draggedSticker);

      // Reorder the stickers based on their new z_render_order (from highest to lowest)
      const reorderedStickers = updatedStickers.map((sticker, idx) => ({
        ...sticker,
        z_render_order: updatedStickers.length - idx - 1, // Assign z_render_order from highest to lowest
      }));

      // Update the local state with the reordered stickers
      setCanvasStickers(reorderedStickers);

      // Update the Redux store to reflect the new z_render_order for each sticker
      reorderedStickers.forEach((sticker, idx) => {
        dispatch(setStickerRenderOrder({
          tabId: activeTab,
          stickerIndex: sticker.index,
          newZIndex: sticker.z_render_order, // Ensure the new z_render_order is assigned
        }));
      });
    }

    // Reset dragging state after drop (whether valid or invalid)
    setDraggedStickerIndex(null);
    setIsDragging(false); // Stop dragging and hide DropBar

    // Reload stickers
    dispatch(requestStickerReload());
  };

  return (
    <div className="this-craft-sidebar-container">
      <div className="skin-selector-box">
        <p>SEARCH FOR ANY CS2 SKIN</p>
        <SelectWeaponField items={weapon_skins} />
      </div>

      {canvasStickers.length > 0 ? (
        <div className="stickers-on-canvas-box">
          <p>{canvasStickers.length}/5 STICKERS APPLIED</p>
        </div>
      ) : (
        <div />
      )}

      {canvasStickers.map((sticker, index) => (
        <div key={sticker.index}>
          {/* Render Sticker Box */}
          <div
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDrop={() => handleDrop(index)} // Drop the sticker at this position
          >
            {sticker === selected_sticker ? (
              <SelectedStickerBox
                sticker={selected_sticker}
                addStickerToCart={addStickerToCart}
                removeStickerFromCanvas={removeStickerFromCanvas}
              />
            ) : (
              <UnselectedStickerBox
                name={sticker.name}
                price={sticker.price}
                steam_price={sticker.steam_price}
                img={sticker.image_path}
                index={sticker.index}
                listing_id={sticker.listing_id}
              />
            )}
          </div>

          {/* Render DropBar between items */}
          {index < canvasStickers.length - 1 && (
            <DropBar
              isDragging={isDragging} // Pass isDragging to DropBar to control visibility
            />
          )}
        </div>
      ))}

      {canvasStickers.length === 0 ? (
        <div className="empty-craft-cell" style={{ userSelect: "none" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src="images/canvas_empty.png"
              alt="Empty Cart Image"
              className="sticker-image-empty-craft"
              style={{
                width: "70%",
                height: "auto",
                margin: "0 auto",
                marginTop: "10px",
                pointerEvents: "none", // Prevent dragging
              }}
            />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "10px",
                marginBottom: "0px",
              }}
            >
              THIS CRAFT IS EMPTY
            </p>

            <div
              style={{
                fontSize: "10px",
                width: "150px",
                marginLeft: "40px",
                marginTop: "5px",
                marginBottom: "30px",
              }}
            >
              <p>YOU CAN APPLY STICKERS ON ANY CS2 SKIN!</p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <button
            className="selected-stickers-clear-canvas"
            onClick={clearCanvas}
          >
            CLEAR CANVAS
          </button>
          <div className="divider-box"></div>
        </div>
      )}
    </div>
  );
};

export default ThisCraftBar;
