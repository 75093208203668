import React, { useState, useEffect, useRef } from "react";
import "./SelectWeaponField.css";
import { useDispatch, useSelector } from "react-redux";
import {
  requestWeaponModelChange,
  setWeaponModelForActiveTab,
} from "../../../redux/slices/tabSystemSlice";

const SelectOptionCellWeaponField = ({ value, onClick }) => {
  const handleClick = () => {
    onClick(value); // Pass the clicked value to the parent component
  };

  return (
    <div
      className="select-option-cell-wpn-field"
      onClick={handleClick} // Call handleClick on click
    >
      {value.skin_name_string} {/* Display the skin name */}
    </div>
  );
};

const SelectWeaponField = ({ items }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab_system.active_tab);

  const [isInputVisible, setInputVisible] = useState(true);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    items.length > 0 ? items[0] : null
  ); // Start with the first item selected
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredItems, setFilteredItems] = useState(items); // State to hold filtered items

  const dropdownRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input element

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelectItem = (item) => {
    const skin_unique_identifier = item.weapon_model;

    const weapon_models = require("./weapon_data.json");

    const matchingWeaponModel = weapon_models.find(
      (model) => model.weapon_model === skin_unique_identifier
    );

    if (matchingWeaponModel) {
      const combinedWeaponModel = {
        ...matchingWeaponModel, // properties from weapon_data.json
        ...item, // properties from the selected item
      };

      dispatch(
        setWeaponModelForActiveTab({
          tabId: activeTab,
          weaponModel: combinedWeaponModel,
        })
      );

      dispatch(requestWeaponModelChange());
    } else {
      console.error("Weapon model not found!");
    }

    setSelectedItem(item);
    setMenuVisible(false);
  };

  const normalizeString = (str) => {
    return str.replace(/[-\s]/g, "").toLowerCase(); // Remove hyphens and spaces, then convert to lowercase
  };

  const handleChange = (event) => {
    const input = event.target.value;
    setSearchTerm(input);

    const searchWords = input.toLowerCase().split(" ");

    const filtered = items.filter((item) => {
      const itemName = normalizeString(item.skin_name_string);
      return searchWords.every((word) =>
        itemName.includes(normalizeString(word))
      );
    });

    setSelectedItem(null); // Clear selected item when typing in the input
    setMenuVisible(true); // Always show menu when there's any text in input
    setFilteredItems(filtered); // Update filtered items state
  };

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select all text in the input field
    }
    setMenuVisible(true); // Always show menu when input is clicked
  };

  return (
    <div className="container-wrapper-wpn-field">
      {isInputVisible && (
        <div
          className="custom-input-dropdown-container-wpn-field"
          ref={dropdownRef}
        >
          <input
            ref={inputRef} // Connect inputRef to the input element
            className="actual-input-wpn-field"
            type="text"
            placeholder="Select an item..."
            value={selectedItem ? selectedItem.skin_name_string : searchTerm}
            onClick={handleInputClick} // Select text when input is clicked
            onChange={handleChange}
          />
          {isMenuVisible && (
            <div className="dropdown-menu-wpn-field">
              {filteredItems.map((item, index) => (
                <SelectOptionCellWeaponField
                  key={index}
                  value={item}
                  onClick={handleSelectItem}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectWeaponField;
