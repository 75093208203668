import React from 'react';
import './HelpSection.css'; // Import the styles
import { useDispatch, useSelector } from "react-redux";
import {
    toggleHelpSection
} from "../../../../redux/slices/tabSystemSlice";

const HelpSection = () => {
    const dispatch = useDispatch();
    const tabs = useSelector((state) => state.tab_system.tabs);
    const activeTab = useSelector((state) => state.tab_system.active_tab);

    const handleQuitClick = () => {
        dispatch(toggleHelpSection()); // Toggles the help section visibility
    };

    // Check if the help section should be displayed
    const isHelpSectionVisible = tabs[activeTab]?.help_section;

    // Conditionally render the help section
    if (!isHelpSectionVisible) {
        return null;
    }

    return (
        <div className="help-section">
            <div className="help-header">
                <span>Keybindings</span>
                <button
                    className="quit-button"
                    onClick={handleQuitClick}
                >
                    <img
                        src="images/cross.png"
                        alt="Quit"
                        className="quit-icon"
                    />
                </button>
            </div>
            <div className="help-content">
                <div className="row">
                    <span>COPY: CTRL+C</span>
                    <span>PASTE: CTRL+V</span>
                </div>
                <div className="row">
                    <span>STACK: ARROW UP/DOWN</span>
                    <span>ROTATE: ARROW LEFT/RIGHT</span>
                </div>
            </div>
        </div>
    );
};

export default HelpSection;
