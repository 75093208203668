import React, { useEffect, useRef, useState } from "react";
import UnSelectedMenuCell from "./UnSelectedMenuCell";

const UnSelectedRightClickMenu = ({ x, y, onCellClick, onClose = () => {} }) => {
  const [position, setPosition] = useState({ left: x, top: y });
  const menuRef = useRef(null);

  useEffect(() => {
    const menuWidth = 170; // Width of the menu
    const menuHeight = 40; // Height of the menu
    const topPadding = 10; // Top padding to ensure menu stays within the window
    const rightPadding = 10; // Padding from the right edge of the window

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Position menu to the right of the mouse cursor with a small offset
    let newLeft = x + 450; // 10px offset to the right of the cursor

    // Ensure the menu doesn't go beyond the right edge of the window
    if (newLeft + menuWidth > windowWidth - rightPadding) {
      newLeft = windowWidth - menuWidth - rightPadding;
    }

    // Ensure the menu stays within the top and bottom bounds
    let newTop = y - 50; // Adjust the top position based on mouse y
    if (newTop < topPadding) {
      newTop = topPadding; // Ensure menu stays within top padding
    }
    if (newTop + menuHeight > windowHeight) {
      newTop = windowHeight - menuHeight; // Ensure menu stays within bottom padding
    }

    setPosition({ left: newLeft, top: newTop });
  }, [x, y]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose(); // Close menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCellClick = (cellText) => {
    onCellClick(cellText); // Invoke the callback with the cell text
  };

  if (!position) {
    return null; // Render nothing if position is null
  }

  return (
    <div
      ref={menuRef}
      style={{
        position: "fixed",
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: "170px",
        height: "40px",
        zIndex: "999",
        overflowY: "auto",
        overflowX: "hidden", // Optional, if needed
      }}
    >
      <UnSelectedMenuCell
        text="Paste Here"
        keyboard_cmd="CTRL + V"
        onClick={() => handleCellClick("Paste")}
      />
    </div>
  );
};

export default UnSelectedRightClickMenu;
