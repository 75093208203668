import React, { useState } from 'react';
import './BottomBarButton.css'; // Import the BottomBarButton styles

const BottomBarButton = ({ size = 'small', text, icon, isToggle = false, onClick }) => {
  const [toggled, setToggled] = useState(false);  // State to track if the button is toggled
  const isLarge = size === 'large';

  // Handle the click event to toggle the state if the button is a toggle
  const handleClick = () => {
    if (isToggle) {
      setToggled(!toggled);  // Toggle the button state on click
    }
    if (onClick) {
      onClick();  // Call the external onClick handler passed as a prop
    }
  };

  return (
    <button
      className={`bottom-bar-button ${isLarge ? 'large' : 'small'} ${toggled ? 'toggled' : ''}`}
      onClick={handleClick}  // Call the handleClick when the button is clicked
    >
      {isLarge && text && (
        <span className="text">{text}</span> // Add text if large button
      )}
      {/* Use image for the icon */}
      <img
        src={icon}
        alt="icon"
        className="icon"
        style={{
          width: isLarge ? '30px' : '20px', // Adjust icon size based on button size
          height: isLarge ? '30px' : '20px',
        }}
      />
    </button>
  );
};

export default BottomBarButton;
