import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active_tab: 0,
  tabs: [
    {
      name: 'Demo craft',
      weaponModel: {
        weapon_model: 'Ak-47_legacy',
        obj_path: 'images/objects/Ak-47/Ak-47-Legacy.obj',
        ao_map_path:
          'images/objects/Ak-47/ak47_ao_psd_286fb1af_orm_2165450181.png',
        normal_map_path: 'images/objects/Ak-47/ak47_normal_psd_57f37ac9.png',
        x_offset: 0,
        y_offset: 0,
        logo_plane_x: -80,
        logo_plane_y: -12,
        logo_plane_scalar: 14,
        model_scale: 8,
        sticker_plate_path:
          'images/objects/Ak-47/ak47_legacy_sticker_plate7.obj',
        sticker_plate_offset_x: 39,
        sticker_plate_offset_y: -0.5,
        skin_name_string: 'Ak-47 | Case Hardened (Blue Gem)',
        skin_texture_path:
          'images/objects/Ak-47/skins/Case_Hardened/Case_Hardened_BlueGem.png',
        float: 0.1318955272436142,
      },
      selected_sticker_index: -1,
      background_url: 'images/inferno.png',
      stickers_applied: [],
      sticker_has_been_added: false,
      should_reload_stickers: false,
      weapon_model_has_changed: false,
      sticker_has_been_selected: false,
      weapon_has_been_loaded: false,
      should_clear_canvas: false,
      should_organize_stickers: false,
      remove_selected_sticker: false,
      remove_all_meshes: false,
      coordinate_system: false,
      help_section: true,
      index: 0,
    },
  ],
  clipBoard: {
    /**
     * Format : {
     *  name,
     *  price,
     *  image_path
     *  x_pos,
     *  y_pos
     * }
     */

    /**
     * Sticker removal can be restored with CMD + Z (CTRL)
     */
    last_removed_sticker: null,
    sticker_has_been_removed: false,

    /**
     * Stickers can be copied with CMD + C, CMD + V (CTRK)
     */
    copied_sticker: null,
    sticker_has_been_copied: false,
    sticker_has_been_pasted: false,
    x_paste_cord: 0,
    y_paste_cord: 0,
  },
};

export const tabSystemSlice = createSlice({
  name: 'tab_system',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.active_tab = action.payload;
    },
    setWeaponModel: (state, action) => {
      state.tabs[state.active_tab].weaponModel = { ...action.payload };
    },
    addTab: (state, action) => {
      const newIndex = state.tabs.length;
      state.tabs.push({
        name: `New Craft ${newIndex + 1}`,
        weaponModel: { ...action.payload },
        selected_sticker_index: 0,
        sticker_size: 200,
        background_url: 'images/backgrounds/nuke.png',
        stickers_applied: [],
        sticker_has_been_added: false,
        should_reload_stickers: false,
        weapon_model_has_changed: false,
        sticker_has_been_selected: false,
        should_organize_stickers: false,
        index: newIndex,
      });
    },
    removeTab: (state, action) => {
      const tabIndexToRemove = action.payload;
      state.tabs = state.tabs.filter((tab) => tab.index !== tabIndexToRemove);
      state.active_tab = Math.min(state.active_tab, state.tabs.length - 1);
      state.tabs.forEach((tab, index) => {
        tab.index = index;
      });
    },
    updateTabName: (state, action) => {
      const { index, name } = action.payload;
      state.tabs[index].name = name;
    },
    moveTab: (state, action) => {
      const { fromIndex, toIndex } = action.payload;
      const movedTab = state.tabs[fromIndex];
      state.tabs.splice(fromIndex, 1);
      state.tabs.splice(toIndex, 0, movedTab);
      state.tabs.forEach((tab, index) => {
        tab.index = index;
      });
    },
    setWeaponModelForActiveTab: (state, action) => {
      const { weaponModel, tabId } = action.payload;
      state.tabs[tabId].weaponModel = { ...weaponModel };
    },
    addSticker: (state, action) => {
      const { tabId, sticker } = action.payload;
      const tabToUpdate = state.tabs.find((tab) => tab.index === tabId);

      if (tabToUpdate) {
        sticker.index = tabToUpdate.stickers_applied.length; // Assign the index based on current length

        // Calculate z_render_order based on stickers not removed
        const countStickersNotRemoved = tabToUpdate.stickers_applied.filter(
          (sticker) => !sticker.has_been_removed
        ).length;
        sticker.z_render_order = countStickersNotRemoved + 1;
        sticker.is_locked = false;

        if (countStickersNotRemoved < 5) {
            tabToUpdate.stickers_applied.push(sticker);
            tabToUpdate.sticker_has_been_added = true; // Set sticker_has_been_added to true
        }
        
      }
    },
    removeSticker: (state, action) => {
      const { tabId, stickerIndex } = action.payload;

      // Find the tab to update
      const tabToUpdate = state.tabs.find((tab) => tab.index === tabId);

      if (tabToUpdate) {
        // Find the sticker within the stickers_applied array
        const stickerToRemove = tabToUpdate.stickers_applied.find(
          (sticker) => sticker.index === stickerIndex
        );

        if (stickerToRemove) {
          // Update the has_been_removed property to true
          stickerToRemove.has_been_removed = true;
          stickerToRemove.z_render_order = -1;

          // Update clipboard with the last deleted sticker
          state.clipBoard.last_removed_sticker = {
            name: stickerToRemove.name,
            price: stickerToRemove.price,
            image_path: stickerToRemove.image_path,
            x_pos: stickerToRemove.x_pos,
            y_pos: stickerToRemove.y_pos,
          };
          state.clipBoard.sticker_has_been_removed = true;
        }
      }
      state.tabs[tabId].selected_sticker_index = -1;
    },

    removeAllStickersInTab: (state, action) => {
      const { tabId } = action.payload;

      // Find the tab to update
      const tabToUpdate = state.tabs.find((tab) => tab.index === tabId);

      if (tabToUpdate) {
        // Loop through all stickers and update them
        tabToUpdate.stickers_applied = [];

        state.clipBoard.sticker_has_been_removed = true;
      }

      // Set the selected sticker index to -1
      state.tabs.find((tab) => tab.index === tabId).selected_sticker_index = -1;
    },

    setSelectedStickerIndex: (state, action) => {
      const { tabId, selectedIndex } = action.payload;
      state.tabs[tabId].selected_sticker_index = selectedIndex;
    },
    updateStickerPosition: (state, action) => {
      const { tabId, stickerIndex, xPos, yPos, zPos } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex] = {
        ...state.tabs[tabId].stickers_applied[stickerIndex],
        x_pos: xPos,
        y_pos: yPos,
        z_pos: zPos,
      };
    },
    
    changeStickerRenderOrder: (state, action) => {
      const { tabId, stickerIndex, moveType } = action.payload;
      const stickers = state.tabs[tabId].stickers_applied;
      const currentRenderOrder = stickers[stickerIndex].z_render_order;

      switch (moveType) {
        case 'back':
          stickers.forEach((sticker, index) => {
            if (
              index !== stickerIndex &&
              sticker.z_render_order < currentRenderOrder
            ) {
              sticker.z_render_order += 1;
            }
          });
          stickers[stickerIndex].z_render_order = 0;
          break;
        case 'forward':
          if (currentRenderOrder < stickers.length) {
            const nextSticker = stickers.find(
              (sticker) => sticker.z_render_order === currentRenderOrder + 1
            );
            if (nextSticker) nextSticker.z_render_order -= 1;
            stickers[stickerIndex].z_render_order += 1;
          }
          break;
        case 'front':
          // Filter stickers to count only those where has_been_removed is false
          const activeStickers = stickers.filter(
            (sticker) => !sticker.has_been_removed
          );

          activeStickers.forEach((sticker, index) => {
            if (
              index !== stickerIndex &&
              sticker.z_render_order > currentRenderOrder
            ) {
              sticker.z_render_order -= 1;
            }
          });
          stickers[stickerIndex].z_render_order = activeStickers.length;
          break;

        case 'backward':
          if (currentRenderOrder > 0) {
            const prevSticker = stickers.find(
              (sticker) => sticker.z_render_order === currentRenderOrder - 1
            );
            if (prevSticker) prevSticker.z_render_order += 1;
            stickers[stickerIndex].z_render_order -= 1;
          }
          break;
        default:
          console.log('Invalid moveType');
      }
    },

    setStickerRenderOrder: (state, action) => {
      const { tabId, stickerIndex, newZIndex } = action.payload;
    
      if (!state.tabs[tabId]) {
        console.error(`Tab with id ${tabId} does not exist.`);
        return;
      }
    
      const stickers = state.tabs[tabId].stickers_applied;
    
      if (!stickers[stickerIndex]) {
        console.error(`Sticker with index ${stickerIndex} does not exist.`);
        return;
      }
    
      if (newZIndex < 0 || newZIndex >= stickers.length) {
        console.error(`Invalid newZIndex: ${newZIndex}`);
        return;
      }

      stickers[stickerIndex].z_render_order = newZIndex+1

    },
    
    

    rotateSticker: (state, action) => {
      const { tabId, stickerIndex, rotation } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex].clockwise_rotatation +=
        rotation;
    },

    setRotationZero: (state, action) => {
      const { tabId, stickerIndex } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex].clockwise_rotatation = 0;
    },

    setStickerDecalStatus: (state, action) => {
      const { tabId, stickerIndex, isAppliedDecal } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex].is_applied_decal =
        isAppliedDecal;
    },
    changeBackgroundUrl: (state, action) => {
      const { tabId, backgroundUrl } = action.payload;
      state.tabs[tabId].background_url = backgroundUrl;
    },

    /**
     * Add new sticker to existing tab without reload.
     */
    requestStickerAdd: (state) => {
      state.tabs[state.active_tab].sticker_has_been_added = true;
    },
    verifyStickerAdd: (state) => {
      state.tabs[state.active_tab].sticker_has_been_added = false;
    },

    /**
     * For changing Z index, applying rotations
     */
    requestStickerReload: (state) => {
      state.tabs[state.active_tab].should_reload_stickers = true;
    },
    verifyStickerReload: (state) => {
      state.tabs[state.active_tab].should_reload_stickers = false;
    },

    /**
     * STICKER ORGANIZATION
     */

    requestStickerOrganization: (state) => {
      state.tabs[state.active_tab].should_organize_stickers = true;
    },
    verifyStickerOrganization: (state) => {
      state.tabs[state.active_tab].should_organize_stickers = false;
    },

    /**
     * For changing weaponmodel
     */
    requestWeaponModelChange: (state) => {
      state.tabs[state.active_tab].weapon_model_has_changed = true;
    },
    verifyWeaponModelChange: (state) => {
      state.tabs[state.active_tab].weapon_model_has_changed = false;
    },

    /**
     * When selecting stickers outside 3D scene.
     */
    requestStickerSelection: (state) => {
      state.tabs[state.active_tab].sticker_has_been_selected = true;
    },
    verifyStickerSelection: (state) => {
      state.tabs[state.active_tab].sticker_has_been_selected = false;
    },

    setDecalIntersectionPoint: (state, action) => {
      const { tabId, stickerIndex, intersectionPoint } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex].decalIntersectionPoint =
        intersectionPoint;
    },

    /**
     * Copy a sticker to clipboard
     */
    setCopiedSticker: (state, action) => {
      const { tabId, stickerIndex } = action.payload;

      const sticker = state.tabs[tabId].stickers_applied[stickerIndex];

      state.clipBoard.copied_sticker = {
        name: sticker.name,
        price: sticker.price,
        image_path: sticker.image_path,
        x_pos: sticker.x_pos,
        y_pos: sticker.y_pos,
      };
      state.clipBoard.sticker_has_been_copied = true;
    },

    /**
     * Call this when pasting stickers. This removes
     * the old copied sticker from clipboard.
     */
    requestStickerPaste: (state) => {
      if (state.clipBoard.sticker_has_been_copied) {
        state.clipBoard.sticker_has_been_pasted = true;
      }
    },
    verifyStickerPaste: (state) => {
      state.clipBoard.copied_sticker = null;
      state.clipBoard.sticker_has_been_copied = false;
      state.clipBoard.sticker_has_been_pasted = false;
    },
    setStickerPasteCoordinates: (state, action) => {
      const { x_cord, y_cord } = action.payload;
      state.clipBoard.x_paste_cord = x_cord;
      state.clipBoard.y_paste_cord = y_cord;
    },

    /**
     * When weaponmodel has loaded
     */
    requestLoadWeaponModel: (state) => {
      state.tabs[state.active_tab].weapon_has_been_loaded = true;
    },
    verifyLoadWeaponModel: (state) => {
      state.tabs[state.active_tab].weapon_has_been_loaded = false;
    },

    /**
     * CLEARING CANVAS
     */

    requestCanvasClearing: (state) => {
      state.tabs[state.active_tab].should_clear_canvas = true;
    },
    verifyCanvasClearing: (state) => {
      state.tabs[state.active_tab].should_clear_canvas = false;
    },

    requestRemoveSelectedSticker: (state) => {
      state.tabs[state.active_tab].remove_selected_sticker = true;
    },
    verifyRemoveSelectedSticker: (state) => {
      state.tabs[state.active_tab].remove_selected_sticker = false;
    },

    /**
     * LOCK STICKER(S)
     */

    lockSticker: (state, action) => {
      /**
       * state : true / false
       */
      const { tabId, stickerIndex, lock_state } = action.payload;
      state.tabs[tabId].stickers_applied[stickerIndex].is_locked = lock_state;
    },



    /**
     * REMOVE ALL MESHES (USED IN TAB SWITCH TO AVOID FAST CHANGE BUG)
     * 
     */

    requestRemoveAllMeshes: (state) => {
      state.tabs[state.active_tab].remove_all_meshes = true;
    },

    verifyRemoveAllMeshes: (state) => {
      state.tabs[state.active_tab].remove_all_meshes = false;
    },

    /**
     * COORDINATE
     */

    toggleCoordinateSystem: (state) => {
      const currentStatus = state.tabs[state.active_tab].coordinate_system;
      state.tabs[state.active_tab].coordinate_system = !currentStatus;  // Toggle the value
    },

    /**
     * HELP SECTION
     */
    toggleHelpSection: (state) => {
      const currentStatus = state.tabs[state.active_tab].help_section;
      state.tabs[state.active_tab].help_section = !currentStatus;  // Toggle the value
    },
    

  },
});

export const {
  setActiveTab,
  setWeaponModel,
  addTab,
  removeTab,
  updateTabName,
  moveTab,
  setWeaponModelForActiveTab,
  addSticker,
  removeSticker,
  setSelectedStickerIndex,
  updateStickerPosition,
  rotateSticker,
  changeBackgroundUrl,
  setStickerDecalStatus,
  requestStickerAdd,
  verifyStickerAdd,
  setDecalIntersectionPoint,
  changeStickerRenderOrder,
  requestStickerReload,
  verifyStickerReload,
  requestWeaponModelChange,
  verifyWeaponModelChange,
  requestStickerSelection,
  verifyStickerSelection,
  setCopiedSticker,
  requestStickerPaste,
  verifyStickerPaste,
  requestLoadWeaponModel,
  verifyLoadWeaponModel,
  removeAllStickersInTab,
  requestCanvasClearing,
  verifyCanvasClearing,
  setStickerPasteCoordinates,
  requestStickerOrganization,
  verifyStickerOrganization,
  lockSticker,
  setRotationZero,
  verifyRemoveSelectedSticker,
  requestRemoveSelectedSticker,
  requestRemoveAllMeshes,
  verifyRemoveAllMeshes,

  // coordinate system
  toggleCoordinateSystem,

  // help section
  toggleHelpSection,

  // new z reder order setter
  setStickerRenderOrder,

} = tabSystemSlice.actions;

export default tabSystemSlice.reducer;
